import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import MainNav from './mainnav'
import Footer from './footer'

import {
  container,
  heading,
  navHeader,
  navFooter,
} from './layout.module.css'

require('../styles/site.css')

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={container}>
      <div className={navHeader}>
        <MainNav />
      </div>
          
      <title>{pageTitle} {data.site.siteMetadata.title}</title>
     
      <main>
        {children}
      </main>

      <div className={navFooter}>
        <Footer />
      </div>

    </div>
  )
}

export default Layout