import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/layout'

const BlogPost = ({ data }) => {

  return (
    <Layout pageTitle="">
      <div class="blog-page-dates">Posted: {data.mdx.frontmatter.date}</div>
      <div class="blog-page-dates">First Published: {data.mdx.frontmatter.firstpub}</div>
        <div class="blog-page-body-background">
          <div class="blog-mdx blog-page-body">
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
        </div>
      </div>
    
     
    
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: {eq: $id}) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        firstpub(formatString: "MMMM DD, YYYY")
        tease
        summary
      }
    }
  }
`

export default BlogPost