import React from 'react'
import { Link, useStaticQuery, graphql, parsePath } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

require('../styles/site.css')

const Avatar = () => {
  return (
    <StaticImage
    alt="That's me!"
    src="../images/zoom-snap.jpg"
    placeholder="blurred"
    layout="fixed"
    width={50}
    height={50}
  />
    
  )
}

export default Avatar