import * as React from "react"

import {
  navContainer,
  navLinkItem,
  navItem,
  navFooter
} from './layout.module.css'

const Footer = () => {
 
  return (
    <div className={navContainer}>
      <div className={navItem}>
        <div className={navLinkItem}>
          <a href="https://github.com/garygealy" rel="noreferrer noopener" target="_blank">
            GitHub
          </a>
        </div>
      </div>

      <div className={navItem}>
        <div className={navLinkItem}>
          <a href="https://linkedin.com/in/ggealy" rel="noreferrer noopener" target="_blank">
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer