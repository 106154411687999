import * as React from 'react'
import { Link } from 'gatsby'
import Avatar from './avatar'

import {
  navContainer,
  navItem,
  navLinkItem,
  navItemAvatar,
  navLinkActive,
} from './layout.module.css'

require('../styles/site.css')

const MainNav = () => {
 
  return (
    <div className={navContainer}>
      <div className={navItemAvatar}>
        <Avatar />
      </div>
      
      <div className={navItem}>
        <div className={navLinkItem}>
          <Link 
            to="/" 
            activeClassName={navLinkActive}
          >
            About
          </Link>
        </div>
      </div>

      <div className={navItem}>
        <div className={navLinkItem}>
          <Link 
            to="/resume"
            activeClassName={navLinkActive}
          >
            Resume
          </Link>
        </div>
      </div>

      <div className={navItem}>
        <div className={navLinkItem}>
          <Link 
            to="/blog" 
            activeClassName={navLinkActive}
          >
            Blog
          </Link>
        </div>
        </div>
    </div>
  )
}

export default MainNav